<template>
  <div>
    <div>
      <create-doctor
        fetchClinicsBy="superAdmin"
        :franchiseId = "franchiseId"
        v-if="franchiseId"
      />
    </div>
  </div>
</template>

<script>
  import createDoctor from "../../components/doctors/createDoctor";

  export default {
    name: "createAdminFranchiseDoctor",
    components: {
      createDoctor
    },
    data:()=>({
      franchiseId: ''
    }),
    created() {
      this.franchiseId = this.$route.params.franchiseId;
    }
  }
</script>

<style scoped>

</style>
